@font-face {
    font-family: "Proxima Nova";
    src: url("./Mark_Simonson_-_Proxima_Nova_Thin.otf") format("opentype"),
    url("./Mark_Simonson_-_Proxima_Nova_Thin.woff") format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./Mark_Simonson_-_Proxima_Nova_Thin_Italic.otf") format("opentype"),
    url("./Mark_Simonson_-_Proxima_Nova_Thin_Italic.woff") format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./Mark_Simonson_-_Proxima_Nova_Light.otf") format("opentype"),
    url("./Mark_Simonson_-_Proxima_Nova_Light.woff") format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./Mark_Simonson_-_Proxima_Nova_Light_Italic.otf") format("opentype"),
    url("./Mark_Simonson_-_Proxima_Nova_Light_Italic.woff") format('woff');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./Mark_Simonson_-_Proxima_Nova_Regular.otf") format("opentype"),
    url("./Mark_Simonson_-_Proxima_Nova_Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./Mark_Simonson_-_Proxima_Nova_Regular_Italic.otf") format("opentype"),
    url("./Mark_Simonson_-_Proxima_Nova_Regular_Italic.woff") format('woff');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./Mark_Simonson_-_Proxima_Nova_Semibold.otf") format("opentype"),
    url("./Mark_Simonson_-_Proxima_Nova_Semibold.woff") format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./Mark_Simonson_-_Proxima_Nova_Semibold_Italic.otf") format("opentype"),
    url("./Mark_Simonson_-_Proxima_Nova_Semibold_Italic.woff") format('woff');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./Mark_Simonson_-_Proxima_Nova_Bold.otf") format("opentype"),
    url("./Mark_Simonson_-_Proxima_Nova_Bold.woff") format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./Mark_Simonson_-_Proxima_Nova_Bold_Italic.otf") format("opentype"),
    url("./Mark_Simonson_-_Proxima_Nova_Bold_Italic.woff") format('woff');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./Mark_Simonson_-_Proxima_Nova_Extrabold.otf") format("opentype"),
    url("./Mark_Simonson_-_Proxima_Nova_Extrabold.woff") format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./Mark_Simonson_-_Proxima_Nova_Extrabold_Italic.otf") format("opentype"),
    url("./Mark_Simonson_-_Proxima_Nova_Extrabold_Italic.woff") format('woff');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./Mark_Simonson_-_Proxima_Nova_Black.otf") format("opentype"),
    url("./Mark_Simonson_-_Proxima_Nova_Black.woff") format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("./Mark_Simonson_-_Proxima_Nova_Black_Italic.otf") format("opentype"),
    url("./Mark_Simonson_-_Proxima_Nova_Black_Italic.woff") format('woff');
    font-weight: 900;
    font-style: italic;
}
