.wrapper {
    margin-top: 50px;
    margin-bottom: 50px;
}

.item {
    margin-bottom: 20px;
}

.title {
    margin-bottom: 5px;
}

.row {
    display: flex;
}

.link {
    margin-left: 10px;
    flex-shrink: 1;
    min-width: 0;
    word-wrap: break-word;
}

.copy {
    cursor: pointer;
    margin-left: 5px;
    margin-right: 5px;
}