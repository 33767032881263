.background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    opacity: 0.5;
    background-color: #00683E;
    z-index: 1;
}

.wrapper {
    position: fixed;
    width: 400px;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    background-color: #FFFFFF;
    box-shadow: 2px 2px 20px 0 rgba(0,0,0,0.25);
    border-radius: 10px;

    padding: 50px 50px 40px 50px;
    box-sizing: border-box;
    z-index: 1;
}

.rendered {

}

.close {
    width: 10px;
    height: 10px;
    background-size: 10px 10px;
    cursor: pointer;
    background-image: url(../images/close-dark.svg);
    position: absolute;
    top: 20px;
    right: 20px;
}

.modal {
    text-align: center;

    color: #00683E;
    font-family: "Proxima Nova", sans-serif;
}

.title {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
    letter-spacing: -1.25px;
    line-height: 30px;
}

.body {
    margin-bottom: 40px;
}

.progressBarContainer {
    margin: 0 auto 40px;
    width: 200px;
    height: 5px;
    border-radius: 2.5px;
    background-color: #e6f0ec;
    position: relative;
}

.progressBar {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-radius: 2.5px;
    background-color: #2BAF2B;
    transition: width 400ms;
}
