.disabled {
    pointer-events: none;
    background: #e9ecef;
    color: white;
}

.drop-file-input {
    position: relative;
    border: 1px dashed #ced4da;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #f0f5f2;
}

.drop-file-input input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
    opacity: 0.6;
}

.drop-file-input_label {
    text-align: center;
    font-weight: 600;
    padding: 10px;
}

.drop-file-input_label img {
    width: 100px;
}
