.fileWrapper {
    border: 1px dashed rgba(0, 104, 62, 0.5);
    border-radius: 10px;
    padding: 30px 10px;
    background-color: rgba(0, 104, 62, 0.05);
    cursor: pointer;
}

.bytes {
    opacity: 0.5;
    font-size: 14px;
    margin-left: 5px;
}

.error {
    color: #F50057;
}