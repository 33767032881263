.refresh-icon {
    background-image: url("./Refresh.svg");
    width: 16px;
    height: 16px;
    display: inline-block;
    cursor: pointer;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.refresh-icon.spinning {
    animation: spin 2s linear infinite;
}