html, body, #root {
  height: 100%;
}

#root {
  display: flex;
}

a,
a:hover {
  color: #2baf2b;
}

.alert-danger a,
.alert-danger a:hover {
  color: #721c24;
  font-weight: bold;
}

.col-nav {
  background: radial-gradient(ellipse at 70% 20%, #48cf1b 0, #2baf2b 50%);
  width: 200px;
  flex-shrink: 0;
  overflow-y: auto;
  padding: 50px 0;
}
.col-nav .nav-link {
  color: white;
  border-bottom: 1px solid white;
}
.col-nav .nav-link:hover, .col-nav .nav-link.active {
  background-color: white;
  color: #2baf2b;
}
.col-nav.beta {
  background: radial-gradient(ellipse at 70% 20%, #757575 0, #6d6d6d 50%);
}
.col-nav.beta .nav-link:hover, .col-nav.beta .nav-link.active {
  color: #6d6d6d;
}
.col-nav.test {
  background: darkorange;
}
.col-nav.test .nav-link:hover, .col-nav.test .nav-link.active {
  color: #6d6d6d;
}
.col-nav.dev {
  background: #ff6961;
}
.col-nav.dev .nav-link:hover, .col-nav.dev .nav-link.active {
  color: #92B558;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #28a745;
}

.col-content {
  flex-grow: 1;
  overflow-y: auto;
}

.logo {
  height: 45px;
  margin: 0 auto 50px;
  display: block;
}

.page-item.active .page-link {
  background-color: #2baf2b;
  border-color: #2baf2b;
}

.page-link,
.page-link:hover {
  color: #2baf2b;
}

svg > g > g:last-child {
  pointer-events: none;
}

.alert {
  white-space: pre-line;
}

.Select-menu-outer {
  z-index: 2;
  width: auto;
  min-width: 100%;
}

.preserve-line-breaks {
  white-space: pre-line;
}
