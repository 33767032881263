.wrapper {
    position: relative;
}

.title {
    margin-bottom: 5px;
    color: #00683E;
    font-family: "Proxima Nova";
    font-size: 14px;
    letter-spacing: -0.88px;
    line-height: 21px;
}

.textfield { /* input don't follow body defaults */
    color: #00683E;
    font-family: "Proxima Nova", sans-serif;
    font-size: 16px;
    line-height: 1.5;

    border-radius: 10px;
    background-color: #FFFFFF;
    padding: 8px 10px;
    border: 0;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
}

.textfieldWithBorder {
    border: 1px solid rgba(0, 104, 62, 0.5);
}

.textfieldWithIcon {
    padding-right: 35px;
}

.textfield::placeholder {
    color: #00683E;
    opacity: 0.5;
    line-height: 1.5;
}

.icon {
    width: 15px;
    height: 15px;
    background-size: 15px 15px;
    cursor: pointer;

    position: absolute;
    right: 10px;
    top: 13px;
}

.iconPasswordShow {
    background-image: url(../../images/password-show.svg);
}

.iconPasswordHide {
    background-image: url(../../images/password-hide.svg);
}

.iconClear {
    background-image: url(../../images/close-primary.svg);
}
