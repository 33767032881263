.link {
    word-wrap: break-word;
    position: relative;
    display: flex;
    align-items: center;
}

.copy {
    cursor: pointer;
    margin-left: 50px;
}

.copied {
    margin-left: 50px;
}