.billing-sender-form-events-select {
    flex-basis: calc(75% / 3) !important;
    flex-grow: 0 !important;
    max-width: calc(75% / 3) !important;

    flex-shrink: 0;
}

.billing-sender-form-events-select .Select-value-label {
    max-width: 150px;
    overflow: hidden;
}