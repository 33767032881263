.icon {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center;
    flex: 0 0 auto;
    align-self: center;
}

.iconEmpty {
    background-image: url(../../images/checkmark-off.svg);
}

.iconChecked {
    background-image: url(../../images/checkmark-on.svg);
}

.iconInvisible {
    background-image: none;
}
