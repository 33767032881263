.pom-form-element {
    width: 165px;
}

.pom-form-element input[type="datetime-local"] {
    width: 190px;
}

.form-group-date-time {
    margin-right: 2.6rem !important;
}

.pom-form-element-inner {
    flex-grow: 1;
}

.pom-form-element input[type="text"] {
    width: 100% !important;
}