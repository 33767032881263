.expandable {
    overflow: hidden;
    position: relative;
}

.expandable-click {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 5px;
    color: white;
    cursor: pointer;
}

